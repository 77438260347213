<template>
  <div>
    <ManageKnowledgeTests />
  </div>
</template>
<script>
import ManageKnowledgeTests from "@/components/ManageKnowledgeTests/ManageKnowledgeTests";
export default {
  components: {
    ManageKnowledgeTests,
  },
  created() {},
};
</script>
